<template>
  <div>
    <van-cell :title="title" >
      <template #right-icon>
        <van-checkbox  v-model="checked_1"  size="24px" @change="onChange" />
      </template>
    </van-cell>
    <div  class="van-cell-labelc">
      {{ label }}
      <van-divider v-if="showDivider"  class="divider"  />
    </div>
  </div>
</template>

<script>
import {Cell, CellGroup,Checkbox,Divider   } from 'vant';
export default {
  components: {
    [Cell.name]:Cell,
    [CellGroup.name]:CellGroup,
    [Checkbox.name]:Checkbox,
    [Divider.name]:Divider
  },
  props:{
    checked:Boolean,
    title:String,
    label:String,
    showDivider:Boolean
  },
  name: "SysCheckbox",
  data(){
    return {
      checked_1:this.checked
    }
  },
  methods:{
    onChange(value){
      this.$emit('change',value)
    }
  },
  watch:{
    checked(){
      this.checked_1 = this.checked;
    }
  }
}
</script>

<style scoped src="../../../../src/css/setting/cell.css"/>
