<template>
  <div>
    <van-cell :title="title" is-link :value="currentItem?currentItem.text:value" @click="showPicker = true">
    </van-cell>
    <div class="van-cell-labelc">
      {{label}}
      <van-divider v-if="showDivider"  class="divider" />
    </div>
    <van-popup v-model="showPicker" round position="bottom">
      <van-picker
          :title="title"
          show-toolbar
          :columns="columns"
          @confirm="onConfirm"
          :default-index="defaultIndex"
          @cancel="showPicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { Cell, CellGroup,Picker,Popup,Divider   } from 'vant';
export default {
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Picker .name]: Picker,
    [Popup .name]: Popup,
    [Divider .name]: Divider
  },
  name: "SysPicker",
  props:{
    columns:Array,
    title:String,
    value:String,
    label:String,
    showDivider:Boolean
  },
  data(){
    return {
      showPicker:false,
      currentItem:this.columns.find(item => item.value === this.value),
      defaultIndex:0

    }
  },
  methods:{
    onConfirm(value, index){
      this.showPicker = false;
      this.currentItem = value;
      this.$emit('onConfirm',value,index)
    }

  },
  created() {
    for (let i = 0; i < this.columns.length; i++) {
        let column = this.columns[i];
        if(column.value === this.value){
          this.defaultIndex = i;
          break;
        }
    }
  }
}
</script>
<style scoped src="../../../../src/css/setting/cell.css"/>
