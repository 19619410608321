<template>
  <div>
    <van-cell :title="title" :is-link="isLink" :value="value" :to="to"/>
    <div  class="van-cell-labelc">
<!--      {{ label }}-->
      <van-divider v-if="showDivider"  class="divider"  />
    </div>
  </div>
</template>

<script>
import {Cell,Divider   } from 'vant';
export default {
  components: {
    [Cell.name]:Cell,
    [Divider.name]:Divider
  },
  props:{
    checked:Boolean,
    title:String,
    value:String,
    to:Object,
    isLink:Boolean,
    label:String,
    showDivider:Boolean
  },
  name: "SysCell",
  data(){
    return {
      checked_1:this.checked
    }
  },
  methods:{
    onChange(value){
      this.$emit('change',value)
    }
  },
  watch:{
    checked(){
      this.checked_1 = this.checked;
    }
  }
}
</script>

<style scoped src="../../../../src/css/setting/cell.css"/>
