
<template>
    <van-pull-refresh v-model="loading" @refresh="loadData">
      <van-skeleton title :row="6" :loading="loading">
        <div>
          <van-collapse v-model="activeNames" accordion>
            <van-collapse-item :name="group.name.hash" v-for="group in form" :title="group.name" :key="group.name.hash">

<!--            <van-cell-group v-for="group in form" :title="group.name" :key="group.name.hash" class="van-cell-group2">-->
              <div v-for="(item,index) in group.itemList" :key="item.id">
                <SysPicker v-if="item.type === 'picker'" @onConfirm="(options,index) => {updateData(item.id,options.value)}"
                           :title="item.title"
                           :value="(item.value)"
                           :label="item.label"
                           :columns="item.options"
                           :showDivider="(index < group.itemList.length -1)"
                  />
                <SysSwitch  v-else-if="item.type === 'switch'"
                            :title="item.title"
                            :label="item.label"
                            :checked="item.value"
                            :showDivider="(index < group.itemList.length -1)" @change="(value) => {updateData(item.id,value)}"/>
                <SysCell v-else-if="item.type === 'cell'"
                         :title="item.title"
                         :label="item.label"
                         :showDivider="(index < group.itemList.length -1)"
                         :isLink="true" :to="{name:item.to,params:{...item.value,id:item.id,label:item.label,title:item.title}}" :value="item.value?item.value.text:null" />
              </div>

<!--            </van-cell-group>-->
            </van-collapse-item>
          </van-collapse>
        </div>
      </van-skeleton>
    </van-pull-refresh>
</template>

<script>
import {Collapse, CollapseItem, Button,Notify,Cell, CellGroup,Form ,Field,Switch
  ,Col,Row,Picker,Popup,Divider,Skeleton,PullRefresh} from 'vant';
import SysPicker from "@/view/setting/component/SysPicker";
import SysSwitch from "@/view/setting/component/SysSwitch";
import SysCheckbox from "@/view/setting/component/SysCheckbox";
import SysCell from "@/view/setting/component/SysCell";
import {getImei} from "@/util/session-storage-utils";
import {getSetting,updateSetting} from "@/api/devicesetting"
import {readCommonParam} from "../../util/session-storage-utils";
export default {
  components: {
    [SysPicker.name]:SysPicker,
    [SysSwitch.name]:SysSwitch,
    [SysCheckbox.name]:SysCheckbox,
    [SysCell.name]:SysCell,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Form.name]: Form,
    [Field.name]: Field,
    [Switch.name]: Switch,
    [Col.name]: Col,
    [Row.name]: Row,
    [Picker .name]: Picker,
    [Popup .name]: Popup,
    [Divider .name]: Divider,
    [Skeleton .name]: Skeleton,
    [PullRefresh .name]: PullRefresh,
    [Button.name]: Button,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem
  },
  props:{
    token:String
  },
  name: "device-setting",
  data() {
    return {
      loading:true,
      form:[],
      activeNames:'',
      checked:false,
      showPicker:false
    }
  },
  methods:{
    updateData(field,value){
      let req = {};
      req[field] = value;
      updateSetting(getImei(),req).then(
          (res) => {
            if(res.code == 0){
              Notify({ type: 'success', message: this.$t('数据更新成功'),duration:500 })
            }

      }
      )
    },
    loadData(){
      let imei = getImei();
      getSetting(imei).then((res) => {
        this.loading = false
        this.form = res.data;
      })
    }

  },
  created(){
    document.title = this.$t("设备设置");
    readCommonParam(this)

    this.loadData();
    // this.loadData();
  },
  activated() {
    document.title = this.$t("设备设置");
    window.console.log("setting activated")
    this.loadData();
  }
}
</script>

<style scoped>
</style>
